import React, { lazy } from 'react';

// define manual import for preventing hook restriction
const DUMMY = {
	CRUD: lazy(() => import('../views/CRUD')),
};

const MasterData = {
	Segment: lazy(() => import('../views/Segment')),
	Regional: lazy(() => import('../views/Regional')),
	Area: lazy(() => import('../views/Area')),
	Roles: lazy(() => import('../views/Roles')),
	FieldInput: lazy(() => import('../views/FieldInput')),
	Distance: lazy(() => import('../views/Distance')),
	Activity: lazy(() => import('../views/Activity')),
	ApprovalSetting: lazy(() => import('../views/ApprovalSetting')),
	DataUser: lazy(() => import('../views/DataUser')),
	Target: lazy(() => import('../views/Target')),
	TTPoint: lazy(() => import('../views/rizky/tt-point/index')),
};

const Input = {
	TTPoint: lazy(() => import('../views/rizky/input/tt-point/index')),
	Skp: lazy(() => import('../views/rizky/input/skp/index')),
	BudgetTarget: lazy(() => import('../views/sodiq/input/budget-target/index')),
};

const Claim = {
	Skp: lazy(() => import('../views/rizky/claim/skp/index')),
	TTPoint: lazy(() => import('../views/rizky/claim/tt-point/index')),
	ImportClaim: lazy(() => import('../views/azhar/claim/importClaim')),
};

const Report = {
	SummeryAccount: lazy(() => import('../views/sodiq/report/summary-account/index')),
	SummaryAll: lazy(() => import('../views/rizky/report/summary-all/index')),
};

const JourneyPlan = {
	SettingPjp: lazy(() => import('../views/SettingPjp')),
	ExportExcel: lazy(() => import('../views/ExportExcel')),
};

const Setting = {
	StructureBagian: lazy(() => import('../views/StructureBagian')),
	StructureJabatan: lazy(() => import('../views/StructureJabatan')),
};

const Generate = {
	GenerateSellInClaim: lazy(() => import('../views/rizky/generate/SellinClaim/index')),
};

const contents = [
	{
		path: null,
		element: <DUMMY.CRUD />,
		index: 'CRUD',
		exact: true,
	},
	{
		path: null,
		element: <JourneyPlan.SettingPjp />,
		index: 'SettingPjp',
		exact: true,
	},
	{
		path: null,
		element: <JourneyPlan.ExportExcel />,
		index: 'ExportExcel',
		exact: true,
	},
	{
		path: null,
		element: <MasterData.Segment />,
		index: 'segment',
		exact: true,
	},
	{
		path: null,
		element: <MasterData.Regional />,
		index: 'regional',
		exact: true,
	},
	{
		path: null,
		element: <MasterData.Area />,
		index: 'area',
		exact: true,
	},
	{
		path: null,
		element: <MasterData.Roles />,
		index: 'role',
		exact: true,
	},
	{
		path: null,
		element: <MasterData.FieldInput />,
		index: 'fieldInput',
		exact: true,
	},
	{
		element: <MasterData.Distance />,
		index: 'distance',
		exact: true,
	},
	{
		element: <MasterData.Activity />,
		index: 'activity',
		path: null,
	},
	{
		element: <MasterData.ApprovalSetting />,
		index: 'ApprovalSetting',
		exact: true,
	},
	{
		path: null,
		element: <MasterData.DataUser />,
		index: 'user',
		exact: true,
	},
	{
		path: null,
		element: <MasterData.Target />,
		index: 'Target',
		exact: true,
	},
	{
		path: null,
		element: <MasterData.TTPoint />,
		index: 'TradingTermsPoint',
		exact: true,
	},
	{
		path: null,
		element: <Setting.StructureBagian />,
		index: 'StructureBagian',
		exact: true,
	},
	{
		path: null,
		element: <Setting.StructureJabatan />,
		index: 'StructureJabatan',
		exact: true,
	},
	{
		path: null,
		element: <Input.TTPoint />,
		index: 'PointTradingTerms',
		exact: true,
	},
	{
		path: null,
		element: <Input.Skp />,
		index: 'Skp',
		exact: true,
	},
	{
		path: null,
		element: <Input.BudgetTarget />,
		index: 'BudgetTarget',
		exact: true,
	},
	{
		path: null,
		element: <Claim.Skp />,
		index: 'SkpClaim',
		exact: true,
	},
	{
		path: null,
		element: <Claim.TTPoint />,
		index: 'TradingTermsClaim',
		exact: true,
	},
	{
		path: null,
		element: <Report.SummaryAll />,
		index: 'SummaryAll',
		exact: true,
	},
	{
		path: null,
		element: <Report.SummeryAccount />,
		index: 'SummaryAccount',
		exact: true,
	},
	{
		path: null,
		element: <Generate.GenerateSellInClaim />,
		index: 'GenerateSellInClaim',
		exact: true,
	},
	{
		path: null,
		element: <Claim.ImportClaim />,
		index: 'ImportClaim',
		exact: true,
	},
];
export default contents;
